//MUI
import { createTheme } from '@mui/material/styles';

import styles from './theme.module.scss';

//Anheuser-Busch MUI Theme Yeah boi
const anheuserBusch22 = createTheme({
  typography: {
    fontFamily: styles.font,
    fontSize: Number(styles.fontSize),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    caption: {
      fontSize: '0.75rem'
    }
  },
  palette: {
    common: {
      black: styles.black,
      white: styles.white
    },
    background: {
      default: styles.white
    },
    primary: {
      main: styles.primary,
      contrastText: styles.white
    },
    secondary: {
      main: styles.secondary,
      contrastText: styles.black
    },
    text: {
      primary: 'rgba(0, 0, 0, 1)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)'
    }
  },

  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true
      },
      styleOverrides: {
        outlined: {
          boxShadow: '0px 2px 1px -2px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0, 0, 0, 0.05),0px 1px 3px 0px rgba(0,0,0,0.15)'
        }
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            fontWeight: 500,
            '&:hover': {
              backgroundColor: styles.darkgold,
              color: styles.black
            },
            '&:active': {
              backgroundColor: styles.darkgold,
              color: styles.black
            }
          }
        },
        {
          props: { variant: 'text' },
          style: {
            fontWeight: 500,
            borderRadius: '1px',
            padding: '.75rem',

            '&:hover': {
              backgroundColor: styles.lightgray,
              color: styles.black
            },
            '&:active': {
              backgroundColor: styles.darkgold,
              color: styles.black
            }
          }
        }
      ]
    }
  }
});

export default anheuserBusch22;
